import React from "react";
import { dsnCN } from "../../../hooks/helper";

function MenuContent({ className }) {
  const socialData = [
    { link: "https://facebook.com/ShohinBonsaiDubai", name: "Facebook" },
    { link: "https://www.tiktok.com/@bonsaidubai", name: "TikTok" },
    { link: "https://www.instagram.com/shohinbonsaidubai", name: "Instagram" },
  ];
  return (
    <div
      className={dsnCN(
        "container-content  d-flex flex-column justify-content-center",
        className
      )}
    >
      <div className="nav__info">
        <div className="nav-content">
          <h5 className="sm-title-block mb-10">Address</h5>
          <p>
            Travo B, Greens
            <br /> Dubai, UAE
          </p>
        </div>
        <div className="nav-content mt-30">
          <h5 className="sm-title-block mb-10">Contact</h5>
          <p className="links over-hidden mb-1">
            <a
              className="link-hover"
              href="tel:+971 50 997 7807"
              data-hover-text="+971 50 997 7807"
            >
              +971 50 997 7807
            </a>
          </p>
          {/* <p className="links over-hidden">
            <a
              className="link-hover"
              href="mailto:info@dsngrid.com"
              data-hover-text="info@dsngrid.com"
            >
              info@dsngrid.com
            </a>
          </p> */}
        </div>
      </div>
      <div className="nav-social nav-content mt-30">
        <div className="nav-social-inner p-relative">
          <h5 className="sm-title-block mb-10">Follow us</h5>
          <ul>
            {socialData.map((item, index) => (
              <li key={index}>
                <a
                  href={item.link}
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  {item.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default MenuContent;
